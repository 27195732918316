import request from '@/utils/request'

// 数据集名称下拉框
export function optionNameList(query) {
  return request({
    url: '/api/ele/drill/dataset/optionNameList',
    method: 'get',
    params: query
  })
}

// 数据量列表
export function queryList(query) {
  return request({
    url: '/api/ele/drill/dataset/list',
    method: 'get',
    params: query
  })
}

// 数据集查看
export function queryDetailById(id) {
  return request({
    url: '/api/ele/drill/dataset/query/'+id,
    method: 'get',
    // params: query
  })
}

// 删除
export function deleteDataSet(id) {
  return request({
    url: '/api/ele/drill/dataset/delete/' + id,
    method: 'delete'
  })
}

// 新增
export function addDataSet(data) {
  return request({
    url: '/api/ele/drill/dataset/add',
    method: 'post',
    data: data
  })
}

// 修改
export function updateDataSet(data) {
  return request({
    url: '/api/ele/drill/dataset/update',
    method: 'post',
    data: data
  })
}

// 图片删除
export function deleteDataSetImage(id) {
  return request({
    url: '/api/ele/drill/dataset/delete/imagefile/' + id,
    method: 'delete'
  })
}

// 标签删除
export function deleteLabelFile(id) {
  return request({
    url: '/api/ele/drill/dataset/delete/labelfile/' + id,
    method: 'delete'
  })
}

// 标签文件下载
export function labelFileDownload(id) {
  return request({
    url: '/api/ele/drill/dataset/download/labelfile/' + id,
    method: 'get'
  })
}
