<template>
    <a-modal :visible="showModal" :width="960" :destroyOnClose="true" @cancel="handleCancel">
        <template v-slot:title>
            <span style="font-size:13px">{{ getHandleType() }}数据集</span>
        </template>
        <template v-slot:closeIcon>
            <a-icon type="close" style="color: #3C4A54;font-size: 12px;margin-top:14px;margin-right:14px;float:right;" />
        </template>

        <template v-slot:footer>
            <div>
                <a-button type="primary" style="" @click="saveReport" v-if="!isView">保存</a-button>
                <a-button style="" @click="handleCancel">关闭</a-button>
            </div>
        </template>
        <div class="main">
            <a-spin :spinning="loading">
            <a-form-model ref="form" :model="form" :rules="rules" :labelCol="{span:3}" :wrapperCol="{span:21}" style="margin:5px; padding-bottom: 10px;">
              <a-row>
                  <a-col :span="24">
                      <a-form-model-item label="数据集名称：" prop="name">
                          <div v-if="this.isView">{{ form.name }}</div>
                          <a-input v-model="form.name" placeholder="请输入数据集名称" v-if="!isView" style="width: 200px"/>
                      </a-form-model-item>
                  </a-col>
                  <a-col :span="24">
                      <a-form-model-item label="备注：" prop="remark">
                          <div v-if="this.isView">{{ form.remark }}</div>
                          <a-textarea v-model="form.remark" placeholder="请输入备注" v-if="!isView" style="height: 48px"/>
                      </a-form-model-item>
                  </a-col>
              </a-row>
          </a-form-model>
          <div class="border_shadow" >
          <div style="background: #E6E6E6;font-size:14px;height:31px;padding-top:3px;padding-left:10px;">
            <span>图片上传<span style="font-size: 12px">（上传原始图像）</span></span>
          </div>

            <div class="img">
                <div class="upload">
                    <div class="upload_warp_left" @click="() => fileClick('img')" style="margin-top: 4px;margin-left:10px;" v-if="!isView">
                        <a-button icon="ios-cloud-upload-outline" class="btn btn-success" type="primary" size="small">上传图片</a-button>
                    </div>
                    <!--<div class="upload_warp_text" v-if="!isView">
                        选中{{imgList.length}}张文件，共{{bytesToSize(this.size)}}
                    </div>-->
                    <div class="upload_warp" style="border: 1px solid white;">
                        <div class="upload_warp_img" v-show="imgList.length!=0" >
                            <div class="upload_warp_img_div" v-for="(item,index) of imgList" :key="index">
                                <div class="upload_warp_img_div_top" >
                                    <!-- <div class="upload_warp_img_div_text" :title="item.file.name">
                                        {{item.file.name}}
                                    </div> -->
                                    <a-button icon="delete" class="upload_warp_img_div_del" style="background-color:transparent" @click="imageDel(index, type)" v-if="!isView"></a-button>
                                </div>
                                <preview-img :src="item.file.src"/>
                                <!-- <img :src="item.file.src"> -->
                            </div>
                        </div>
                    </div>
                    <input @change="fileChange($event, 'img')" type="file" id="upload_img" multiple style="display: none"/>
                </div>
            </div>
         </div>
         <div class="border_shadow" >
            <div style="background: #E6E6E6;font-size:14px;height:31px;padding-top:3px;padding-left:10px;">
               <span>文件上传<span style="font-size: 12px">（上传json文件）</span></span>
            </div>

            <div class="img" style="padding-left:10px;">
                <div class="upload">
                    <div class="upload_warp_left" @click="() => fileClick('file')" style="margin-top: 4px;" v-if="!isView">
                        <a-button icon="ios-cloud-upload-outline" class="btn btn-success" type="primary" size="small">上传文件</a-button>
                    </div>
                    <!--<div class="upload_warp_text" v-if="!isView">
                        选中{{fileList.length}}张文件，共{{bytesToSize(this.fileSize)}}
                    </div>-->
                    <div class="upload_warp" style="border: 1px solid white;">
                        <div class="upload_warp_img" v-show="fileList.length!=0" >
                            <div v-for="(item,index) of fileList" :key="index" class="upload_file_single">
                                <a @click="downloadLabel(item)">{{ item.name }}</a>
                                <div class="file_del" @click="fileDel(index)"  v-if="!isView">X</div>
                            </div>
                        </div>
                    </div>
                    <input @change="fileChange($event, 'file')" type="file" id="upload_file" multiple style="display: none"/>
                </div>
            </div>
        </div>
        </a-spin>
        </div>
    </a-modal>
</template>

<script>
    import { queryDetailById, addDataSet, updateDataSet, deleteDataSetImage, deleteLabelFile } from "@/api/ai/dataSet.js";
    import {  message, Modal } from 'ant-design-vue';
    import PreviewImg from './components/PreviewImg';
    import { baseUrl } from '../utils/request';

    export default {
        name: "DataDialog",
        components: { PreviewImg },
        props: {
            showModal: {
                type: Boolean,
                default: false
            },
            reportData: {
                type:Object,
                default:null
            },
            handelType: {
               type: String,
               default: 'add'
            }
        },

        data() {
            return {
                loading: false,
                form: {},
                imgList: [],
                fileList: [],
                size: 0,
                fileSize: 0,
                rules: {
                    name: [
                        { required: true, message: "数据集名称不能为空", trigger: ["change", "blur"]}
                    ],
                },
                isView: false,
            }
        },
        created() {
        },
        watch: {
            showModal(value) {
                if (this.showModal) {
                    this.form = {};
                    this.imgList = [];
                    this.fileList = [];
                    this.isView = this.handelType === 'view';
                    this.queryDataById();
                }
            }
        },
        methods:{
            // 获取详情数据
            queryDataById(){
                if(!this.reportData.id) {
                    return;
                }
                this.loading = true;
                queryDetailById(this.reportData.id).then(res => {
                    this.loading = false;
                    if(res.success && res.data) {
                        const current = res.data || {};
                        this.form = current;
                        this.imgList = current.imageList?.map(ele => {
                            const file = {
                                id: ele.id,
                                name: ele.name,
                                src: 'data:image/jpeg;base64,' + ele.imageCode
                            };
                            return {
                                ...ele,
                                file
                            };
                        }) || [];
                        this.fileList = current.labelList || []
                    }
                });
            },
            fileClick(type){
                // 上传按钮
                if(type === 'img') {
                    document.getElementById('upload_img').click();
                }

                if(type === 'file') {
                    document.getElementById('upload_file').click();
                }

            },
            fileChange(el, type){
                if (!el.target.files[0].size) return;
                this.getFileList(el.target.files, type);
                el.target.value = ''
            },
            getFileList(files, type){
                for (let i = 0; i < files.length; i++) {
                    this.fileAdd(files[i], type);
                }
            },
            fileAdd(file, type){
                if(type === 'file') {
                    this.fileSize = this.fileSize + file.size;//总大小
                    this.fileList.push(file);
                }
                if(type === 'img') {
                    this.size = this.size + file.size;//总大小
                    let reader = new FileReader();
                    reader.vue = this;
                    reader.readAsDataURL(file);
                    reader.onload = function () {
                        file.src = this.result;
                        this.vue.imgList.push({
                            file
                        });
                    }
                }
            },
            async imageDel(index){
                const that = this;
                const item = this.imgList[index];
                Modal.confirm({
                    title: '提示',
                    content: '是否确认删除该图片？',
                    okText: '确认',
                    cancelText: '取消',
                    onOk() {
                        if (item.id) {
                            deleteDataSetImage(item.id).then(res => {
                            if(res.success) {
                                that.size = that.size - (that.imgList[index].file.size || 0);//总大小
                                that.imgList.splice(index, 1);
                            }
                            });
                        }else {
                            that.size = that.size - (that.imgList[index].file.size || 0);//总大小
                            that.imgList.splice(index, 1);
                        }
                    }
                });
            },
            async fileDel(index){
                 const that = this;
                const item = this.fileList[index];
                Modal.confirm({
                    title: '提示',
                    content: '是否确认删除该标签文件？',
                    okText: '确认',
                    cancelText: '取消',
                    onOk() {
                        if (item.id) {
                            deleteLabelFile(item.id).then(res => {
                            if(res.success) {
                                that.size = that.size - (that.fileList[index].size || 0);//总大小
                                that.fileList.splice(index, 1);
                            }
                            });
                        }else {
                            that.size = that.size - (that.fileList[index].size || 0);//总大小
                            that.fileList.splice(index, 1);
                        }
                    }
                });
            },
            bytesToSize(bytes){
                if (bytes === 0) return '0 B';
                let k = 1000, // or 1024
                    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
                    i = Math.floor(Math.log(bytes) / Math.log(k));
                return (bytes / Math.pow(k, i)).toPrecision(3) + ' ' + sizes[i];
            },
            dragenter(el){
                el.stopPropagation();
                el.preventDefault();
            },
            dragover(el){
                el.stopPropagation();
                el.preventDefault();
            },
            drop(el){
                el.stopPropagation();
                el.preventDefault();
                this.fileList(el.dataTransfer.files);
            },

            handleChange({ uploaderFileList}){
                this.uploadedFileList = uploaderFileList;
            },

            saveReport(res){
                if(this.imgList.length === 0 && this.fileList.length === 0) {
                    message.error("未上传图片/文件");
                    return;
                }
                this.$refs["form"].validate(async valid => {
                    if (valid) {
                        this.loading = true;
                        const formData = new FormData();
                        if(this.form.id) {
                            formData.append('id', this.form.id);
                        }
                        formData.append('name', this.form.name);
                        formData.append('remark', this.form.remark);
                        for (let i = 0; i < this.imgList.length; i++) {
                            if (this.imgList[i].file && !this.imgList[i].file?.id) {
                                formData.append("images", this.imgList[i].file);
                            }
                        }
                        for (let i = 0; i < this.fileList.length; i++) {
                            if (this.fileList[i].name && !this.fileList[i].id) {
                                formData.append("labels", this.fileList[i]);
                            }
                        }
                        const res = this.form.id ? await updateDataSet(formData) : await addDataSet(formData);
                        this.loading = false;
                        if(res.success) {
                            message.success("操作成功");
                            this.handleCancel();
                            this.$emit("onFresh");
                        }
                    }
                });
            },

            // 下载标签文件
            downloadLabel(item) {
                if(item.id) {
                    window.open(baseUrl + '/api/ele/drill/dataset/download/labelfile/'+item.id);
                }
            },

            handleCancel(){
                this.$emit("close");
            },
            getHandleType() {
                if(this.handelType === 'add') {
                    return "新增";
                }
                if(this.handelType === 'update') {
                    return "编辑";
                }
                if(this.handelType === 'view') {
                    return "查看";
                }
            }

        }
    }
</script>

<style scoped>
    .dialogTitle{
        background: lightgray;
        font-size:12px;
    }
    .main {
        background: white;
    }

    .subtitle {
        font-weight: bolder;
        font-size: 24px;
        text-align: center;
        color:black;
    }

    .head {
        height: 61px;
        background: url("../assets/banner.png") no-repeat;
        background-size:224px 61px
    }

    /*/deep/ .ant-modal-content{*/
    /*  padding: 0px;*/
    /*  margin:0px;*/
    /*  border:1px solid blue;*/
    /*}*/
    /deep/ .ant-modal-header {
  /* background: lightgray; */
        height: 31px;
        padding-top: 3px;
        padding-left: 6px;
        border-bottom: 1px solid #EEEEEE;
    }
    /deep/ .ant-modal-body {
        padding: 0px;
    }
    /deep/ .ant-row{
        margin:0px;
        padding:0px;
    }
    .upload-list-inline >>> .ant-upload-list-item {
        float: left;
        width: 200px;
        margin-right: 8px;
    }
    .upload-list-inline >>> .ant-upload-animate-enter {
        animation-name: uploadAnimateInlineIn;
    }
    .upload-list-inline >>> .ant-upload-animate-leave {
        animation-name: uploadAnimateInlineOut;
    }
    .upload_warp_img_div_del {
        position: absolute;
        width: 16px;
        right: 8px;
        left: 50%;
    }
    .upload_warp_img_div_top {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.4);
        line-height: 30px;
        text-align: left;
        color: #fff;
        font-size: 12px;
        text-indent: 4px;
    }
    .upload_warp_img_div_text {
        white-space: nowrap;
        width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .upload_warp_img_div img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }

    .upload_warp_img_div {
        position: relative;
        height: 100px;
        width: 120px;
        border: 1px solid #ccc;
        margin: 0px 5px 5px 0px;
        float: left;
        line-height: 100px;
        display: table-cell;
        text-align: center;
        background-color: #eee;
        cursor: pointer;
    }

    .upload_warp_img {
        /* border-top: 1px solid #D2D2D2; */
        padding: 5px 0 0 5px;
        overflow: hidden
    }

    .upload_warp_text {
        text-align: left;
        margin-bottom: 5px;
        padding-top: 5px;
        text-indent: 14px;
        border-top: 1px solid #ccc;
        font-size: 14px;
    }

    .upload_warp_right {
        float: left;
        width: 57%;
        margin-left: 2%;
        height: 100%;
        border: 1px dashed #999;
        border-radius: 4px;
        line-height: 130px;
        color: #999;
    }
    .upload_warp_left button {
        margin: 1px 5px 0px 0px;
        cursor:pointer;
    }
    .upload_warp_left {
        float: left;
    }
    .upload_warp_left .btn-success{
        background: #4F60BA;
        color:#fff;
    }
    .upload_warp {
        margin: 5px;
        clear: both;
    }
    .upload {
        /* border-left: 1px solid #ccc; */
        /* border-right: 1px solid #ccc; */
        background-color: #fff;
        width: 100%;
        /* box-shadow: 0px 1px 0px #ccc; */
        /* border-radius: 4px; */
    }
    .img {
        width: 100%;
        text-align: center;
    }
    /* 查看 */
    .lookPng{
        margin-top: 20px;
        min-height: 30px;
        height: 100%;
    }
    .upload_file_single {
        display: flex;
        justify-content: space-between;
        width: 98%;
    }
    .upload_file_single .file_del {
        cursor: pointer;
    }
    .ant-select, .ant-input{
    width: 816px!important;
}
.border_shadow{
    margin: 0px 20px 8px 20px;
    margin-top: 0px;
    box-shadow: 0px 1px 12px 0px rgba(61,74,148,0.5);
    border-radius: 4px;
}
.img .ant-btn{
        color: white;
        border: none;
    }
</style>
