var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-container"},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('div',{staticClass:"table-search"},[_c('a-form-model',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSearch),expression:"showSearch"}],ref:"queryForm",attrs:{"model":_vm.queryParams,"size":"small","layout":"inline","label-width":"68px"}},[_c('a-form-model-item',{attrs:{"label":"数据集名称","prop":"name"}},[_c('a-select',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请选择数据集名称","allowClear":"","show-search":""},model:{value:(_vm.queryParams.name),callback:function ($$v) {_vm.$set(_vm.queryParams, "name", $$v)},expression:"queryParams.name"}},_vm._l((_vm.datasetList),function(item){return _c('a-select-option',{key:item.name,attrs:{"value":item.name}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-button',{attrs:{"icon":"search","type":"primary"},on:{"click":_vm.handleQuery}},[_vm._v("查询")]),_c('a-button',{staticStyle:{"margin-left":"10px"},attrs:{"icon":"a-icon-refresh"},on:{"click":_vm.resetQuery}},[_vm._v("重置")])],1),_c('a-form-model-item',{staticStyle:{"float":"right","margin-right":"0px"}},[_c('a-button',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['aidataset_add']),expression:"['aidataset_add']"}],attrs:{"type":"primary","icon":"plus-square"},on:{"click":_vm.handleAdd}},[_vm._v("新增")]),_c('a-button',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['aidataset_delete']),expression:"['aidataset_delete']"}],staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","icon":"delete","disabled":_vm.multiple},on:{"click":_vm.handleDelete}},[_vm._v("删除")])],1)],1)],1),_c('div',{staticClass:"card",staticStyle:{"padding":"6px","height":"calc(100vh - 160px)"}},[_c('a-table',{ref:"table",attrs:{"rowKey":"id","columns":_vm.columns,"dataSource":_vm.tableList,"row-selection":_vm.rowSelection,"pagination":{
      defaultCurrent: _vm.queryParams.pageNum, // 默认当前页数
      defaultPageSize: _vm.queryParams.pageSize, // 默认当前页显示数据的大小
      total: _vm.total, // 总数，必须先有
      showSizeChanger: true,
      showQuickJumper: true,
      pageSizeOptions: ['20', '50', '100'],
      showTotal: (total, range) => `每页${_vm.queryParams.pageSize}条，共 ${total} 条`, // 显示总数
      onShowSizeChange: _vm.onPageSizeChange,
      // 改变每页数量时更新显示
      onChange: _vm.onPageSizeChange,
    },"bordered":""},scopedSlots:_vm._u([{key:"action",fn:function(record){return [_c('a',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['aidataset_view']),expression:"['aidataset_view']"}],staticStyle:{"margin-right":"10px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.handleView(record)}}},[_c('a-icon',{attrs:{"type":"eye"}}),_vm._v(" 查看")],1),_c('a',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['aidataset_edit']),expression:"['aidataset_edit']"}],staticStyle:{"margin-right":"10px"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.handleUpdate(record)}}},[_c('a-icon',{attrs:{"type":"form"}}),_vm._v(" 编辑")],1),_c('a',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['aidataset_delete']),expression:"['aidataset_delete']"}],attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.handleDelete(record)}}},[_c('a-icon',{attrs:{"type":"delete"}}),_vm._v(" 删除")],1)]}}])}),_c('div',{staticStyle:{"position":"relative","margin-left":"6px"}},[_vm._v("注意：数据集不少于100张")])],1),_c('ai-data-set-dialog',{attrs:{"show-modal":_vm.open,"report-data":_vm.detailData,"handelType":_vm.handelType},on:{"onFresh":_vm.onFresh,"close":_vm.closeDialog}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }